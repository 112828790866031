$transition-duration: .5s;
$scroll-duration: .7s;

html.wf-loading {
	body {
		opacity: 0;
		transform: translateY(30px);
	}
}

body {
	transition: opacity 1s;
	opacity: 0;

	&.loaded {
		opacity: 1;
	}
}

.section {
	&.startSection {
		.fp-tableCell {
			padding-top: 100px !important;
		}
	}

	.fp-controlArrow {
		top: 0;
		bottom: 80px;
		margin-top: 0;
		width: 50%;
		height: auto;
		opacity: 0.3;
		transition: opacity $transition-duration;
		border: none;

		&.fp-prev {
			cursor: url('../Images/cursor-left-arrow.png'),auto;
			left: 0;
		}

		&.fp-next {
			cursor: url('../Images/cursor-right-arrow.png'),auto;
			right: 0;
		}

		&:hover,
		&.sliding {
			opacity: 1;
		}
	}

	&.backgroundSection {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;

		.fp-tableCell {
			padding: 32px 0;

			@media only screen and (min-width: 64em) {
				padding: 0;
			}
		}

		@media only screen and (min-width: 64em) {
			&.startSection {
				.fp-tableCell {
					padding-top: 100px !important;
				}
			}
		}

		&.withOverlay {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: rgba(0, 0, 0, 0.78)
			}

			> .fp-tableCell {
				position: relative;
				z-index: 2;
			}
		}
	}

	&.halfOverlaySection {
		padding-top: 0;

		.row.expanded.collapse {
			height: auto;

			> .columns {
				height: auto;

				&:first-of-type {
					background: rgba(0, 0, 0, 0.8);
					padding: 8% 4%;

					@media only screen and (min-width: 90em) {
						padding: 8% 5%;
					}

					@media only screen and (min-width: 105em) {
						padding: 11% 5%;
					}
				}
			}

			@media only screen and (min-width: 64em) {
				height: 100vh;

				> .columns {
					height: 100%;

					&.large-6 {
						&:first-of-type {
							width: 66.66667%;
							padding: 8% 4%;
						}

						&:last-of-type {
							width: 33.33333%;
						}
					}
				}
			}

			@media only screen and (min-width: 87.5em) {
				> .columns {
					&.large-6 {
						&:first-of-type {
							padding: 8% 4%;
						}

						&:first-of-type,
						&:last-of-type {
							width: 50%;
						}
					}
				}
			}

			@media only screen and (min-width: 90em) {
				> .columns {
					&.large-6 {
						&:first-of-type {
							padding: 8% 5%;
						}
					}
				}
			}

			@media only screen and (min-width: 105em) {
				> .columns {
					&.large-6 {
						&:first-of-type {
							padding: 11% 5%;
						}
					}
				}
			}
		}

		&.overlayRight {
			.row.expanded.collapse {
				> .columns {
					&:first-of-type {
						background: none;
						padding: 0;
					}

					&:last-of-type {
						background: rgba(0, 0, 0, 0.8);
						padding: 8% 4%;

						@media only screen and (min-width: 90em) {
							padding: 8% 5%;
						}

						@media only screen and (min-width: 105em) {
							padding: 11% 8%;
						}
					}

					@media only screen and (min-width: 64em) {
						&.large-6 {
							&:first-of-type {
								width: 33.33333%;

							}

							&:last-of-type {
								padding: 8% 4%;
								width: 66.66667%;
							}
						}
					}

					@media only screen and (min-width: 87.5em) {
						&.large-6 {
							&:first-of-type,
							&:last-of-type {
								width: 50%;
							}

							&:last-of-type {
								padding: 8% 4%;
							}
						}
					}

					@media only screen and (min-width: 90em) {
						&.large-6 {
							&:last-of-type {
								padding: 8% 5%;
							}
						}
					}

					@media only screen and (min-width: 105em) {
						&.large-6 {
							&:last-of-type {
								padding: 11% 8%;
							}
						}
					}
				}
			}
		}
	}

	&.kontaktSection {
		> .fp-tableCell > .row > .columns > .row {
			max-width: 1160px;
			margin: 0 auto;
			margin-top: 2rem;
		}

		.ce-media {
			text-align: center;
		}
	}
}

body[data-whatinput="touch"] {
	.section {
		.fp-controlArrow {
			top: 50%;
			margin-top: -84.5px;
			width: 0;
			height: 0;

			&.fp-prev {
				background: url('../Images/arrow-gallery-left.png') no-repeat left center;
				left: 20px;
			}

			&.fp-next {
				background: url('../Images/arrow-gallery-right.png') no-repeat right center;
				right: 20px;
			}

			@media only screen and (min-width: 500px) {
				width: 64px;
				height: 169px;
			}

			@media only screen and (min-width: 800px) {
				&.fp-prev {
					left: 100px;
				}

				&.fp-next {
					right: 100px;
				}
			}
		}
	}
}

.slide {
	position: relative;

	&.bg-slide {

		.slide-background {
			position: relative;
		}

		.slideContent {
			position: absolute;
			left: 170px;
			right: 170px;

			.box {
				p {
					&:last-of-type {
						margin-bottom: 0;

						> .button {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		/*.fp-tableCell {
			text-align: center;
		}*/
	}
}

.fp-slide .box.bgcolor-1 {

	h1,h2,h3,h4,h5,p,a,span {
		color: inherit;
	}
}

.slideTooltip {
	display: block;
	opacity: 0;
	width: 0;
	margin: 0 auto;
	cursor: pointer;
	position: relative;
	bottom: 65px;

	.slideTooltipInner {
		white-space: nowrap;
		padding: 3px 6px;
		font-size: 14px;
		background: #000;
		background: rgba(0,0,0,0.6);
		margin-left: 10px;
	}
}

.slideTooltipWrapper {
	position: absolute;
	bottom: 30px;
	width: 100%;
	text-align: center;
	margin-bottom: 30px;

	.gallerySlidePlay,
	.slideTooltipTrigger {
		color: #fff;
		cursor: pointer;
		opacity: 0;
		transition: opacity $transition-duration;
		font-size: 1.6rem;
		padding: 10px;
		pointer-events: none;

		&.sliding {
			opacity: 0 !important;
		}

		&.visible {
			pointer-events: initial;
			opacity: 1;
		}

		&:hover {
			~ .slideTooltip {
				width: auto;
				opacity: 1;
				transition: opacity $transition-duration;
			}
		}
	}

	.gallerySlidePlay {
		position: absolute;
		right: 0;
		top: 0;
	}

	@media only screen and (min-width: 500px) {
		margin-bottom: 0;
		text-align: left;

		.slideTooltipTrigger {
			margin-left: 2%;
		}

		.gallerySlidePlay {
			right: initial;
			left: 2%;
			margin-left: 45px;
		}
	}
}

/* ------ InImageScroller ------ */
.inImageScroller {

	.outerImage {
		position: relative;
		overflow: hidden;

		img {
			position :relative;
			pointer-events: none;
		}
	}

	.innerImages {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		transition: transform $transition-duration;

		img {
			margin: 0 auto;
			max-height: 100%;
			display: block;
			max-width: none;
			width: 100%;
			user-select: none;
		}
	}
}

.fp-responsive .inImageScroller .innerImages {
	transform: translateY(0) !important;
	overflow: scroll;
}

/* ------ TeaserRow ------ */
.teaserRow {
	margin-top: 1rem;

	.teaser {

		.teaserImage {
			width: 300px;
			height: 300px;
			max-width: 100%;
			text-align: center;
			position: relative;
			cursor: pointer;
			opacity: 0.65;
			transition: opacity $transition-duration;
			margin: 0 auto;

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}

			.teaserName {
				position: relative;
			}

		}

		.teaserContent {
			opacity: 0;
			transition: opacity $transition-duration, transform $transition-duration;
			margin: 2rem 0 0 0;
			text-align: center;
			transform: translateY(30px);
		}

		&.act {

			.teaserImage {
				opacity: 1;
			}

			.teaserContent {
				opacity: 1;
				transform: translateY(0px);
			}

		}
	}

	@media only screen and (min-height: 700px) {
		margin-top: 5rem;
	}
}

.valign {
	display: table;
	width: 100%;
	height: 100%;
}

.valign > .valignInner {
	display: table-cell;
	width: 100%;
	vertical-align: middle;
}

.veneerDesignerEmbed {
	border: none;
	height: 50px;
}

.ce-media-gallery {
	@media only screen and (min-width: 40em) {
		.ce-media {
			max-width: 200px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	@media only screen and (min-width: 87.5em) {
		.ce-media {
			max-width: none;
		}
	}
}